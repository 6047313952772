<div class="container-fluid">
  <form class="form-horizontal" #f="ngForm">

    <div class="page-header header-xs flex">
      <h4 class="flex-left">Données à remplir par le demandeur</h4>
      <span class="flex-right action-btn-bar" *ngIf="hasPermissions('Antoine:CanPrintLabel')">
        <app-print-label-button [disabled]="mode === 'edition'" [buildPrintData]="buildPrintData" [detail]="woi"></app-print-label-button>
      </span>
    </div>

    <div class="form-group">
      <label class="col-md-4 col-lg-2 control-label">Modèle d'équipement<i class="required">*</i></label>
      <div class="col-md-8 col-lg-4">
        <app-equipment-model-field class="wcm-highlight-req" [disabled]="mode === 'normal'" [required]="true"
                                   [filters]="equipmentModelField.filters"
                                   [(ngModel)]="equipmentModel" name="equipmentModel"
                                   (ngModelChange)="onChangeEquipmentModel($event)">
        </app-equipment-model-field>
      </div>


      <label class="col-md-4 col-lg-2 control-label">Numéro de série</label>
      <div class="col-md-8 col-lg-4 flex">
        <div class="flex-left">
          <p class="form-control-static">{{woi.metadata.requested_serial_number}}</p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-md-4 col-lg-2 control-label">Besoin de production</label>
      <div class="col-md-8 col-lg-4 flex">
        <div class="flex-left">
          <p class="form-control-static">{{woi.metadata.external_id}}</p>
        </div>
      </div>

      <label class="col-md-4 col-lg-2 control-label">Etiquette</label>
      <div class="col-md-8 col-lg-4 flex">
        <div class="flex-left">
          <p class="form-control-static">{{woi.metadata.requested_printing_label}}</p>
        </div>
      </div>
    </div>

    <div *ngIf="woi.metadata.sim_slot_1_apn || woi.metadata.sim_slot_1_iccid">
      <div class="form-group">
        <strong class="col-md-4 col-lg-2 control-label">Slot sim 1</strong>
      </div>

      <div class="form-group">
        <label class="col-md-4 col-lg-2 control-label">APN</label>
        <div class="col-md-8 col-lg-4 flex">
          <div class="flex-left">
            <p class="form-control-static">{{woi.metadata.sim_slot_1_apn}}</p>
          </div>
        </div>
        <label class="col-md-4 col-lg-2 control-label">ICCID</label>
        <div class="col-md-8 col-lg-4 flex">
          <div class="flex-left">
            <input class="form-control" [(ngModel)]="woi.metadata.sim_slot_1_iccid" maxlength=25
                    name="sim_slot_1_iccid" [readonly]="mode === 'normal' || !['error-stock'].includes(woi.state.name)">
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="woi.metadata.sim_slot_2_apn || woi.metadata.sim_slot_2_iccid">
      <div class="form-group">
        <strong class="col-md-4 col-lg-2 control-label">Slot sim 2</strong>
      </div>

      <div class="form-group">
        <label class="col-md-4 col-lg-2 control-label">APN</label>
        <div class="col-md-8 col-lg-4 flex">
          <div class="flex-left">
            <p class="form-control-static">{{woi.metadata.sim_slot_2_apn}}</p>
          </div>
        </div>
        <label class="col-md-4 col-lg-2 control-label">ICCID</label>
        <div class="col-md-8 col-lg-4 flex">
          <div class="flex-left">
            <input class="form-control" [(ngModel)]="woi.metadata.sim_slot_2_iccid" maxlength=25
                    name="sim_slot_2_iccid" [readonly]="mode === 'normal' || !['error-stock'].includes(woi.state.name)">
          </div>
        </div>
      </div>
    </div>

    <div class="page-header header-xs" style="margin-top: 30px;">
      <h4>Données à remplir par la logistique</h4>
    </div>

    <div *ngIf="woi && ['new', 'ready'].includes(woi.state.name); else acceptedLogisticsBlock">
      <div class="alert alert-warning">
        Le formulaire de provisionnement sera disponible lorsque la tâche aura été prise en charge.
      </div>
    </div>
    <ng-template #acceptedLogisticsBlock>

      <div class="form-group">
        <div *ngIf="woi.metadata?.latest_provi_history_message">
          <label class="col-md-2 col-lg-2 control-label">
            Message
          </label>
          <div class="col-md-4 col-lg-4 flex">
            <pre>{{woi.metadata?.latest_provi_history_message}}</pre>
          </div>
        </div>

      </div>

      <div class="form-group" *ngIf='!isProductionEnvironment'>
        <label class="col-md-2 col-lg-2 control-label">
          Magic test flags 🧪
        </label>
        <div class="col-md-4 col-lg-4 flex">
          <div class="flex-left">
            <input class="form-control" name="testFlags" [(ngModel)]="testFlags" />
          </div>
        </div>
      </div>

      <app-console-field [(ngModel)]="selectedConsole" name="selectedConsole"
                         [disabled]="isDisabledField()">
      </app-console-field>

      <div class="form-group">
        <label class="col-md-2 col-lg-2 control-label">
          Equipement
        </label>
        <div class="col-md-4 col-lg-4 flex" *ngIf="providedEqpCode">
          <a routerLink="/equipments/detail/{{providedEqpCode}}">
            {{providedEqpCode}}
          </a>
        </div>
        <label class="col-md-2 col-lg-2 control-label">
          Numéro de série
        </label>
        <div class="flex-left">
          <p class="form-control-static">{{woi.metadata?.provided_serial_number}}</p>
        </div>
      </div>
      <div class="form-group">
        <div class="col-md-offset-4 col-lg-offset-2 col-md-8 col-lg-4">
          <button class="btn btn-primary" (click)="startProviFlashage()"
                  [disabled]="isDisabledStartProvisionning()">
            Démarrer le provisionnement
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</div>
