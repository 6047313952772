import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { EQP_LOCATIONS } from '@core/constants';
import { IEquipment } from '@core/interfaces';

@Component({
  selector: 'app-equipments-select-modal',
  templateUrl: './equipments-select-modal.component.html',
  styles: []
})
export class EquipmentsSelectModalComponent implements OnInit {
  @Input() public eqpList: IEquipment[];

  public items: IEquipment[] = [];
  public locationOptions = EQP_LOCATIONS;

  constructor(
    public modal: NgbActiveModal,
  ) { }

  public ngOnInit(): void {
    this.eqpList?.forEach((item) => { this.items.push(item); });
  }

}
