<div class="modal-header">
  <button type="button" class="close" (click)="modal.dismiss()">&times;</button>
  <h4 class="modal-title">Sélection d'équipement</h4>
</div>

<div class="modal-body">
  <p-table #dt [value]="items" class="wcm-table">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 13%;" class="text-left">Code</th>
        <th class="text-left">Constructeur</th>
        <th class="text-left">Modèle</th>
        <th class="text-left">N° de série</th>
        <th class="text-left">N° de série interne</th>
        <th class="text-left">Réservé pour</th>
        <th class="text-left">@Mac</th>
        <th style="width: 10%;" class="text-left">Affectation</th>
        <th style="width: 10%;"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item let-ri="rowIndex">
      <tr>
        <td>
          <a [routerLink]="'/equipments/detail/' + item.code" target="_blank">
            <i class="fas fa-external-link-alt"></i> {{ item.code }}
          </a>
        </td>
        <td>{{ item.brand }}</td>
        <td>{{ item.model.name }}</td>
        <td>{{ item.serial_number }}</td>
        <td>{{ item.internal_serial_number }}</td>
        <td>{{ item.reserved_for?.name }}</td>
        <td>{{ item.mac_address }}</td>
        <td>{{ locationOptions[item.location] }}</td>
        <td class="text-center">
          <div class="action-btn-bar">
            <button type="button" class="btn btn-default btn-xs" (click)="modal.close(item)">
              Sélectionner
            </button>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
