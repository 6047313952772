import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { ScannedEquipmentListComponent } from '@core/components/scanned-equipment-list/scanned-equipment-list.component';
import { ScannedEquipmentManager } from '@core/components/scanned-equipment-list/scanned-equipment.manager';
import { EQP_LOCATIONS, FormMode } from '@core/constants';

import {
  IEquipment,
  IGenericListOptions,
  IMetadataScannedEquipment,
  IMiniEquipment,
  IPrintLabelItem_new,
  IPrintLabelObject_new,
  ITinyEquipment,
  ITinyEquipmentModel,
  IWorkOrderItems,
} from '@core/interfaces';


import { SignalsService } from '@core/services/signals.service';
import { PromisesService } from '@core/services/promises.service';
import { UserService } from '@core/services/user.service';
import { EquipmentScanSearchService } from '@views/work-order-items/work-order-items-metadata-templates/equipment-scan-search.service';

interface IPmamerMetadata {
  model: ITinyEquipmentModel;
  equipment_model_id: number;
  quantity: number;
  equipments: IMiniEquipment[];
}

@Component({
  selector: 'app-pmamer-metadata',
  templateUrl: './pmamer-metadata.component.html',
  styleUrls: ['../work-order-items-detail-metadata.component.less']
})
export class PmamerMetadataComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('f', { static: true }) public f: NgForm;
  @ViewChild('serialNumberInput') public serialNumberInput: ElementRef;
  @ViewChild('scannedEquipmentList', { static: true }) public scannedEquipmentList: ScannedEquipmentListComponent;

  @Input() public mode: FormMode = 'normal';
  @Input() public woi: IWorkOrderItems;
  @Input() public woiSave: Function; // woi save method to call on seq update

  public loading: boolean;
  public entityCode: string;
  public merakiType: 'meraki_mx' | 'meraki_ms' | 'meraki_mr' | 'meraki_mg' | null = null;
  public locationOptions = EQP_LOCATIONS;
  public equipmentModel: ITinyEquipmentModel;
  public equipmentModelFieldOptions: IGenericListOptions;
  public canEditEquipmentModel: boolean = false;
  public canEditQuantityField: boolean = false;
  public isNetworkView: boolean;
  public serialNumber: string;
  public allEquipmentScanned: boolean = false;
  public hasInvalidScannedEquipment: boolean = false;

  private metadataBackup: IPmamerMetadata;
  private woiEditionCancelledSignalHandler: Subscription;
  private pmamerRefreshSignalHandler: Subscription;

  constructor(
    private readonly apiShiva: ApiShivaService,
    private readonly signalsService: SignalsService,
    private readonly promisesService: PromisesService,
    private readonly userService: UserService,
    private readonly toastr: ToastrService,
    private readonly scannedEquipmentManager: ScannedEquipmentManager,
    private readonly equipmentScanSearchService: EquipmentScanSearchService,
  ) { }

  public ngOnInit(): void {
    this.woi.metadata = this.woi.metadata || {} as IPmamerMetadata;

    this.woiEditionCancelledSignalHandler = this.signalsService.subscribe('woi-edition-cancelled', () => {
      this.woi.metadata = {
        ...this.woi.metadata,
        ...this.metadataBackup,
      };
      this._init();
    });

    this.pmamerRefreshSignalHandler = this.signalsService.subscribe('pmamer-metadata-refresh', () => this._init());

    // allow the buildPrintData method to be called from other components
    this.buildPrintData = this.buildPrintData.bind(this);

    this._init();
  }

  /**
   *  This function will be called for every input change, so the mode will trigger a change too,
   *   but we can't properly detect if the woi has changed because it's structure is too complex
   *  Handle the metadata update from the parent view (ex: 'cancel' action that does a backup)
   */
  public ngOnChanges(changes: SimpleChanges): void {
    const previousMode = changes?.mode?.previousValue;
    const currentMode = changes?.mode?.currentValue;

    if (previousMode === 'normal' && currentMode === 'edition') {
      this.metadataBackup =  JSON.parse(JSON.stringify(this.woi.metadata));
      setTimeout(() => this.serialNumberInput.nativeElement.focus());
    }

    if (previousMode === 'edition' && currentMode === 'normal') {
      this._checkScannedEquipmentValidity();
    }
  }

  public ngOnDestroy(): void {
    this.woiEditionCancelledSignalHandler.unsubscribe();
    this.pmamerRefreshSignalHandler.unsubscribe();
  }

  public updateScannedEquipmentMetadata(updatedEquipmentMetadata: IMetadataScannedEquipment[]): void {
    this.woi.metadata.equipments = [...updatedEquipmentMetadata];
    this._updateScannedEquipment();
  }

  public hasPermissions(...permissions: string[]): boolean {
    return this.userService.hasPermissions(...permissions);
  }

  public async onChangeEquipmentModel(newVal: ITinyEquipmentModel): Promise<void> {
    if (!newVal) {
      return;
    }
    this.woi.metadata.equipment_model_id = this.equipmentModel && this.equipmentModel.id;
    this._setMerakiType(this.equipmentModel.type_label);
  }

  public searchForSerial(): void {
    this.f.controls.serialNumber.markAsTouched();
    this.loading = true;
    this.equipmentScanSearchService.searchBySerial(this.serialNumber, this.woi.metadata.equipments || [])
      .then((equipment: IEquipment) => {
        this.serialNumber = null;
        return this._addScannedEquipment(equipment);
      })
      .catch((message: string | undefined) => {
        if (message) {
          this.toastr.error(message, 'Recherche Equipement');
        }
      })
      .finally(() => this.loading = false);
  }

  public buildPrintData(currentEqp: IMiniEquipment = null): Promise<IPrintLabelObject_new> {
    const deferred = this.promisesService.defer();

    const parentName = this.woi?.work_order?.entity?.parent?.name;
    const customerRef = this.woi?.work_order?.entity?.customer_ref;
    const locationCity = this.woi?.location?.city;

    const labels = this._getPrintDataEqpObj(currentEqp);
    const printData = {
      woi_code: this.woi.code || '',
      quantity: labels.length || this.woi.metadata.quantity || 1,
      equipments_codes: [],
      labels_data: {
        entity_code: this.woi?.work_order?.entity?.code || '',
        parent_name: parentName || '',
        customer_ref: customerRef || locationCity || '',
        labels: labels,
        lr_code: this.woi?.logistics_request?.code || '',
        company_name: this.woi.metadata?.company_name || parentName || '',
      }
    };
    deferred.resolve(printData);

    return deferred.promise;
  }

  private _init(): void {
    this.equipmentModelFieldOptions = {
      filters: {
        category__label: 'meraki'
      },
      disabledButtons: {
        create: true
      }
    };

    this._fetchEquipmentModel(this.woi?.metadata?.equipment_model_id);
    this.canEditEquipmentModel = ['ready', 'in-progress'].includes(this.woi?.state?.name);
    this.canEditQuantityField = ['ready', 'in-progress'].includes(this.woi?.state?.name);

    this.isNetworkView = ['network-provi', 'network-in-progress', 'done'].includes(this.woi?.state?.name);

    if (['in-progress'].includes(this.woi?.state?.name)) {
      this._checkScannedEquipmentValidity();
    }
    // allow the buildPrintData method to be called from other components
    this.buildPrintData = this.buildPrintData.bind(this);
  }

  /**
   * Load the equipment model from the ID saved in the metadata. If the ID is not provided, set the equipment model to undefined.
   * @param equipmentModelId The equipment model to loiad
   * @private
   */
  private _fetchEquipmentModel(equipmentModelId: number): void {
    if (!equipmentModelId) {
      this.equipmentModel = undefined;
      return;
    }

    this.apiShiva.equipment_models.detail(equipmentModelId)
      .then((res: ITinyEquipmentModel) => this.equipmentModel = res)
      .catch((err: unknown) => {
        const errorMsg = `Impossible de récupérer les informations liées au modèle ${equipmentModelId}. Veuillez rafraîchir votre page.`;
        this.toastr.error(errorMsg);
        console.error(err);
      });
  }

  private _setMerakiType(typeLabel: string): void {
    switch (typeLabel) {
      case 'Borne WiFi':
        this.merakiType = 'meraki_mr';
        break;
      case 'Switch':
        this.merakiType = 'meraki_ms';
        break;
      case 'Firewall':
        this.merakiType = 'meraki_mx';
        break;
      case 'Meraki MG':
        this.merakiType = 'meraki_mg';
        break;
    }
  }

  private _addScannedEquipment(equipment: ITinyEquipment): void {
    const newScannedEquipments: IMetadataScannedEquipment[] = this.scannedEquipmentManager.addScannedEquipment(
      equipment,
      this.woi.metadata.equipments,
      this.woi.metadata.requested_equipment,
      this.woi,
      this.equipmentModel,
    );
    // Add the scanned equipment metadata to the work order items metadata
    this.woi.metadata.equipments = [...newScannedEquipments];
    this._updateScannedEquipment();
  }

   private _updateScannedEquipment(): void {

    // Update the view to reflect the new values
    this._checkScannedEquipmentValidity();

    // Make sure we update the work order item, in case the user refreshes the page or something
    this.woiSave();

    // Select the serial number input again for the next equipment
    setTimeout(() => this.serialNumberInput.nativeElement.focus());
  }

  private _checkScannedEquipmentValidity(): void {
    const { allScanned, hasErrors } = this.scannedEquipmentManager.checkScanStatus(this.woi.metadata.quantity, this.woi.metadata.equipments);
    this.allEquipmentScanned = allScanned;
    this.hasInvalidScannedEquipment = hasErrors;

  }

  private _getPrintDataEqpObj(currentEqp: IMiniEquipment): IPrintLabelItem_new[] {
    const printLabelItem: IPrintLabelItem_new[] = [];

    if (!currentEqp) {
      const eqpList = this.woi?.metadata?.equipments;
      eqpList.forEach((eqp: IMiniEquipment) => {
        printLabelItem.push({
          equipment_name: eqp.seq,
        });
      });
    } else {
      printLabelItem.push({
        equipment_name: currentEqp.seq,
      });
    }

    return printLabelItem;
  }
}
