import { inject, Injectable } from '@angular/core';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { IEquipment, IListResult, IMetadataScannedEquipment, ScanStatusEnum } from '@core/interfaces';
import { EquipmentsSelectModalComponent } from '@views/equipments/equipments-select-modal.component';

@Injectable({
  providedIn: 'root',
})
export class EquipmentScanSearchService {
  private readonly apiShiva = inject(ApiShivaService);
  private readonly toastr = inject(ToastrService);
  private readonly ngbModal = inject(NgbModal);

  public async searchBySerial(serialNumber: string, scannedEquipments: IMetadataScannedEquipment[]): Promise<IEquipment | undefined> {
    return new Promise<IEquipment>((resolve: (value: IEquipment) => void, reject: (reason?: string) => void) => {
      if (!serialNumber) {
        reject();
      }
      this.apiShiva.equipments.list({ serial_search: serialNumber })
        .catch((err: unknown) => {
          console.error(err);
          this.toastr.error('Erreur lors de la recherche. Veuillez essayer à nouveau.');
        })
        .then((listResult: IListResult<IEquipment>) => this._handleResults(listResult, serialNumber, scannedEquipments))
        .then((equipment: IEquipment) => resolve(equipment))
        .catch((error: string) => reject(error));
    });
  }

  private _handleResults(listResult: IListResult<IEquipment>, serialNumber: string, scannedEquipments: IMetadataScannedEquipment[]): Promise<IEquipment> {
    return new Promise<IEquipment>((resolve: (value: IEquipment) => void, reject: (reason?: string) => void) => {
      if (listResult.count === 0) {
        reject(`Aucun équipement correspondant avec le numéro de série ${serialNumber}.`);

      } else if (listResult.count === 1) {
        const alreadyAdded: boolean = this._checkAlreadyAdded(listResult.results[0], scannedEquipments);
        if (alreadyAdded) {
          reject(`L'équipemnt correspondant au numéro de série ${serialNumber} a déjà été ajouté.`);
        } else {
          resolve(listResult.results[0]);
        }

      } else if (listResult.count > 1) {
        const modal = this.ngbModal.open(EquipmentsSelectModalComponent, { backdrop: 'static', size: 'lg' });
        modal.componentInstance.eqpList = listResult.results;
        modal.result
          .then((res: IEquipment) => {
            const alreadyAdded: boolean = this._checkAlreadyAdded(res, scannedEquipments);
            if (alreadyAdded) {
              reject(`L'équipement correspondant au numéro de série ${serialNumber} a déjà été ajouté.`);
            } else {
              resolve(res);
            }
          })
          .catch(() => reject());

      } else {
        reject(`Un problème lié à la recherche est survenu. Veuillez ré-essayer.`);
      }
    });
  }

  private _checkAlreadyAdded(equipment: IEquipment, scannedEquipments: IMetadataScannedEquipment[]): boolean {
    const validStates: ScanStatusEnum[] = [ScanStatusEnum.Success, ScanStatusEnum.Warning, ScanStatusEnum.Error];
    const validScannedCodes: string[] = scannedEquipments
      .filter((scanned: IMetadataScannedEquipment) => validStates.includes(scanned.scan_status))
      .map((scanned: IMetadataScannedEquipment) => scanned.code);
    const alreadyAdded: boolean = validScannedCodes.includes(equipment.code);
    console.log('alreadyAdded', alreadyAdded);
    return alreadyAdded;
  }
}
